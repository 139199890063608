<template>
  <v-main>
    <ToolBar :links="links" />
    <div style="padding: 20px">
      <sequential-entrance fromRight>
        <router-view />
      </sequential-entrance>
    </div>
  </v-main>
</template>

<script>
import ToolBar from "../digitaltp_shared/ToolBar.vue";

export default {
  name: "ProjectMain",
  components: {
    ToolBar,
  },
  data: () => ({
    links: [
      {
        name: "Dashboard",
        icon: "mdi-flare",
        routinglink: "/components/dashboard",
      },
      {
        name: "Components",
        icon: "mdi-clipboard-list-outline",
        routinglink: "/components/listcomponents",
      },
    ],
  }),
};
</script>

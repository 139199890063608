<template>
  <v-main>
    <ToolBar :links="links" />
    <div style="padding: 20px">
      <sequential-entrance fromRight>
        <router-view />
      </sequential-entrance>
    </div>
  </v-main>
</template>

<script>
import ToolBar from "../../digitaltp_shared/ToolBar.vue";

export default {
  name: "ProjectMain",
  components: {
    ToolBar,
  },
  data: () => ({
    links: [      
      {
        name: "Objectives",
        icon: "mdi-flare",
        routinglink: "/dtpme/objectives",
      },    
      {
        name: "M&E Report",
        icon: "mdi-finance",
        routinglink: "/dtpme/me_report",
      }
    ],
  }),
};
</script>

<template>
  <v-main>
    <ToolBar :links="links" />
    <div style="padding: 20px">
      <sequential-entrance fromRight>
        <router-view />
      </sequential-entrance>
    </div>
  </v-main>
</template>

<script>
import ToolBar from "../../digitaltp_shared/ToolBar.vue";

export default {
  name: "ProjectMain",
  components: {
    ToolBar,
  },
  data: () => ({
    links: [
      {
        name: "General Report",
        icon: "mdi-flare",
        routinglink: "/reports/general_report",
      },
      {
        name: "Projects Reports",
        icon: "mdi-cash-multiple",
        routinglink: "/reports/general-report",
      },
      {
        name: "Resource Reports",
        icon: "mdi-account-hard-hat",
        routinglink: "",
      },
      {
        name: "Custom Report",
        icon: "mdi-tools",
        routinglink: "",
      },
    ],
  }),
};
</script>

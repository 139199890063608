import SettingsMain from './SettingsMain.vue'


export default [
  {
    path: "/settings",
    name: "SettingsMain",
    component: SettingsMain,
    children:[
      {
        path: "institutions",
        name: "Institutions",
        component: () =>
          import(/* webpackChunkName: "Institutions" */ "./Institutions.vue"),
      },
    ]
  }
  
]
import ProjectMain from './ProjectMain.vue'
import ReportsMain from './digitaltp_reports/ReportsMain.vue'
import MEMain from './digitaltp_me/MEMain.vue'
import DataEntryMain from './digitaltp_data_entry/DataEntryMain.vue'


export default [
  {
    path: "/components",
    name: "ProjectMain",
    component: ProjectMain,
    children: [
      {
        path: "dashboard",
        name: "ProgramComponents",
        component: () =>
          import(/* webpackChunkName: "ProgramComponents" */ "./digitaltp_components/ProgramComponents.vue"),
      },
      {
        path: "listcomponents",
        name: "ProgramComponentsList",
        component: () =>
          import(/* webpackChunkName: "ProgramComponentsList" */ "./digitaltp_components/ProgramComponentsList.vue"),
      },

      {
        path: "component_details",
        name: "ComponentDetails",
        component: () =>
          import(/* webpackChunkName: "ComponentDetails" */ "./digitaltp_components/component_details/ComponentDetails.vue"),
      },

      {
        path: "component_subcomponents",
        name: "ProgramSubComponents",
        component: () =>
          import(/* webpackChunkName: "ProgramSubComponents" */ "./digitaltp_subcomponents/ProgramSubComponents.vue"),
      },

      {
        path: "component_projects",
        name: "WorkingTable",
        component: () =>
          import(/* webpackChunkName: "ComponentDetails" */ "./digitaltp_components/component_details/WorkingTable.vue"),
      },

      {
        path: "project_details",
        name: "ProjectDetails",
        component: () =>
          import(/* webpackChunkName: "ProjectDetails" */ "./digitaltp_components/project_details/ProjectDetails.vue"),
      },

      {
        path: "project_sub_activity",
        name: "ProjectSubActivity",
        component: () =>
          import(/* webpackChunkName: "ProjectDetails" */ "./digitaltp_components/project_details/projectSubActivities.vue"),
      },
      {
        path: "project_sub_activity_view",
        name: "ProjectSubActivityView",
        component: () =>
          import(/* webpackChunkName: "ProjectDetails" */ "./digitaltp_components/project_details/projectSubActivitiesView.vue"),
      },
      {
        path: "projectTrendBarCharts",
        name: "projectTrendBarCharts",
        component: () =>
          import(/* webpackChunkName: "ProjectDetails" */ "../digitaltp_charts/projectTrendBarCharts.vue"),
      },
    ]
  },
  {
    path: "/reports",
    name: "ReportsMain",
    component: ReportsMain,
    children: [
      {
        path: "general_report",
        name: "GeneralReport",
        component: () =>
          import(/* webpackChunkName: "GeneralReport" */ "./digitaltp_reports/GeneralReport.vue"),
      },
      {
        path: "general-report",
        name: "GeneralReport",
        component: () =>
          import(/* webpackChunkName: "ProjectDetails" */ "../digitaltp_projects/reports/GeneralReport.vue"),
      },
      {
        path: "report-component",
        name: "ReportPerComponent",
        component: () =>
          import(/* webpackChunkName: "ProjectDetails" */ "../digitaltp_projects/reports/reportPerComponent.vue"),
      },
    ]
  },
  {
    path: "/dtpme",
    name: "MEMain",
    component: MEMain,
    children: [
      {
        path: "objectives",
        name: "MEObjectives",
        component: () =>
          import(/* webpackChunkName: "MEObjectives" */ "./digitaltp_me/MEObjectives.vue"),
      },
      {
        path: "objectives_details",
        name: "MEObjectivesTargets",
        component: () =>
          import(/* webpackChunkName: "MEObjectivesTargets" */ "./digitaltp_me/MEObjectivesTargets.vue"),
      },
      {
        path: "target_indicators",
        name: "METargetsIndicators",
        component: () =>
          import(/* webpackChunkName: "METargetsIndicators" */ "./digitaltp_me/METargetsIndicators.vue"),
      },
      {
        path: "me_report",
        name: "MEReport",
        component: () =>
          import(/* webpackChunkName: "MEReport" */ "./digitaltp_me/MEReport.vue"),
      },
    ]
  },
  {
    path: "/data_entry",
    name: "DataEntryMain",
    component: DataEntryMain,
    children: [
      {
        path: "projects_data",
        name: "Projects",
        component: () =>
          import(/* webpackChunkName: "Projects" */ "./digitaltp_data_entry/project_data/Projects.vue"),
      },
      {
        path: "entry_types",
        name: "Projects",
        component: () =>
          import(/* webpackChunkName: "Projects" */ "./digitaltp_data_entry/project_data/EntryType.vue"),
      },
    ]
  }
  
]
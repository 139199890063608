<template>
  <v-main>
    <ToolBar :links="links" />
    <div style="padding: 20px">
      <sequential-entrance fromRight>
        <router-view />
      </sequential-entrance>
    </div>
  </v-main>
</template>

<script>
import ToolBar from "../../digitaltp_shared/ToolBar.vue";

export default {
  name: "ProjectMain",
  components: {
    ToolBar,
  },
  data: () => ({
    links: [
      {
        name: "Projects",
        icon: "mdi-note-plus",
        routinglink: "/data_entry/projects_data",
      },
      {
        name: "Monitoring",
        icon: "mdi-clipboard-list-outline",
        routinglink: "/data_entry/monitoring_data",
      },
      {
        name: "Sites Evidence",
        icon: "mdi-clipboard-list-outline",
        routinglink: "/data_entry/evidence_data",
      },
    ],
  }),
};
</script>

<template>
  <v-main>
    <ToolBar :links="links" />
    <div style="padding: 20px">
      <sequential-entrance fromRight>
        <router-view />
      </sequential-entrance>
    </div>
  </v-main>
</template>

<script>
import ToolBar from "../digitaltp_shared/ToolBar.vue";

export default {
  name: "SettingsMain",
  components: {
    ToolBar,
  },
  data: () => ({
    links: [
      {
        name: "Institutions List",
        icon: "mdi-domain",
        routinglink: "/settings/institutions",
      },
      {
        name: "Roles Managements",
        icon: "mdi-account-star",
        routinglink: "/settings/listsettings",
      },
      {
        name: "Project Categories",
        icon: "mdi-border-inside",
        routinglink: "/settings/listsettings",
      },
      {
        name: "Project Locations",
        icon: "mdi-map-marker-multiple-outline",
        routinglink: "/settings/listsettings",
      },
      {
        name: "Currency Settings",
        icon: "mdi-currency-eur",
        routinglink: "/settings/listsettings",
      },
      {
        name: "Users Managements",
        icon: "mdi-account-multiple-outline",
        routinglink: "/settings/listsettings",
      },
    ],
  }),
};
</script>
